import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { authorized } from 'htcore';
import { FORM_NAMES } from 'components/form';
import { date } from 'simple';
import { searchSaveForm } from 'tasks/accommodation/search-create';
import { searchFormValuesCorrection } from 'tasks/accommodation/search-form-formatter';
import { $ui } from 'stores';

const BANNERS = ['/images/hotels/footer/jumeirah-marsa.jpg'];

let banner;

const FooterBanner = observer(() => {
    const navigate = useNavigate();

    if (!banner) {
        banner = BANNERS[Math.trunc(Math.random() * (BANNERS.length - 0.01))];
    }

    const startSearch = () => {
        if (!authorized()) {
            navigate('/signup');
            return;
        }
        const destinationName = 'Jumeirah Marsa Al Arab';
        let destinationHtId = 'Accommodation_27446414';

        const form = searchFormValuesCorrection(
            $ui.formCache?.[FORM_NAMES.SearchForm]
                ? JSON.parse($ui.formCache[FORM_NAMES.SearchForm])
                : {
                      roomDetails: [
                          {
                              adultsNumber: 2,
                              childrenAges: [],
                          },
                      ],
                  }
        );

        const values = {
            ...form,
            destinationInput: destinationName,
            destination: destinationHtId,
        };

        // todo: remove for any other banner
        const stopSaleDay = new Date(2025, 2, 29);
        if (date.isFuture(stopSaleDay)) {
            values.checkInDate = stopSaleDay;
            values.checkOutDate = date.addDay(stopSaleDay, 1);
        }

        $ui.formCache[FORM_NAMES.SearchForm] = JSON.stringify(values);
        searchSaveForm(values, () => null, navigate);
    };

    return <img className="image pointer" src={banner} alt="Jumeirah Marsa Al Arab" onClick={startSearch} />;
});

export default FooterBanner;
