import React from 'react';
import { observer } from 'mobx-react';
import { $auth } from 'stores';
import { Routes, Route } from 'react-router-dom';
import { AuthKeeperComponent } from 'htcore/auth/pages';
import PageTemplate from './template/page-template';

import AccommodationTitlePage from 'pages/accommodation/title';
import AccommodationSearchResults from 'pages/accommodation/search-results/search-results';
import AccommodationBooking from 'pages/accommodation/booking/booking';
import AccommodationContractsSets from 'pages/accommodation/room-contract-sets/room-contract-sets';
import AccommodationConfirmation from 'pages/accommodation/booking-confirmation';
import AccommodationViewBooking from 'pages/accommodation/view-booking-page';
import AccommodationDetails from 'pages/accommodation/details';
import PaymentPage from 'pages/payment/payment-page';
import CabinetPage from 'pages/cabinet/cabinet-page';
import BookingsManagement from 'pages/bookings-management/bookings-management';
import NotFoundPage from 'pages/public/not-found-page';
import SignIn from 'pages/signup/signin';

const PrivateRoutes = observer(() => (
    <AuthKeeperComponent>
        <PageTemplate key={$auth.token}>
            <Routes>
                <Route path="/search" element={<AccommodationTitlePage />} />
                <Route path="/login" element={<SignIn />} />
                <Route path="/search/contract" element={<AccommodationContractsSets />} />
                <Route path="/search/results" element={<AccommodationSearchResults />} />
                <Route path="/search/results/empty" element={<AccommodationSearchResults />} />
                <Route path="/accommodation/booking" element={<AccommodationBooking />} />
                <Route path="/accommodation/confirmation" element={<AccommodationConfirmation />} />
                <Route path="/bookings/:filterTab" element={<BookingsManagement />} />
                <Route path="/bookings/:filterTab/:filterSubtab" element={<BookingsManagement />} />
                <Route path="/bookings" element={<BookingsManagement />} />
                <Route path="/booking/:code" element={<AccommodationViewBooking />} />
                <Route path="/htid/:htId" element={<AccommodationDetails />} />
                <Route path="/payment/form" element={<PaymentPage />} />
                <Route path="/settings/*" element={<CabinetPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </PageTemplate>
    </AuthKeeperComponent>
));

export default PrivateRoutes;
