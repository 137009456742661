import React from 'react';
import Tiles from 'components/tiles';
import Search from 'pages/accommodation/search-form/search-form';
import { observer } from 'mobx-react';
import ItineraryNotification from './custom-search/itinerary-notification';

const AccommodationTitlePage = observer(({ noSearch }) => (
    <>
        <ItineraryNotification />
        {!noSearch && (
            <div className="search-fullsize-wrapper">
                <Search fullsize key="search-full" />
            </div>
        )}
        <div className="tiles block">
            <section>
                <h2>Exclusive offers</h2>
                <Tiles
                    list={[
                        {
                            title: 'Atlantis The Royal, Dubai',
                            image: '/images/hotels/exclusive-offers/atlantis.jpg',
                            destinationName: 'Atlantis The Royal, Dubai, United Arab Emirates',
                            htId: 'Accommodation_27025595',
                        },
                        {
                            title: 'The Dubai Edition',
                            image: '/images/hotels/exclusive-offers/edition.jpg',
                            destinationName: 'The Dubai Edition, Dubai, United Arab Emirates',
                            htId: 'Accommodation_27010249',
                        },
                        {
                            title: 'Jumeirah Marsa Al Arab',
                            image: '/images/hotels/exclusive-offers/jumeirah-marsa.jpg',
                            destinationName: 'Jumeirah Marsa Al Arab, Dubai, United Arab Emirates',
                            htId: 'Accommodation_27446414',
                        },
                        {
                            title: 'Le Royal Meridien Beach Resort And Spa Dubai',
                            image: '/images/hotels/exclusive-offers/le-meridien.jpg',
                            destinationName:
                                'Le Royal Meridien Beach Resort And Spa Dubai, Dubai, United Arab Emirates',
                            htId: 'Accommodation_27012144',
                        },
                    ]}
                />
                <h2>Our Latest Direct Partnerships</h2>
                <Tiles
                    list={[
                        {
                            city: 'Aman Resorts',
                            style: {
                                background: `#f3eee7 url("/images/hotels/partnership/aman.svg") center center no-repeat`,
                                backgroundSize: '40%',
                            },
                            destinationName: 'Amanfayun, Hangzhou, China',
                            htId: 'Accommodation_27116318',
                        },
                        {
                            city: 'The Chedi Hotels',
                            style: {
                                background: `#fafafa url("/images/hotels/partnership/chedi.svg") center center no-repeat`,
                                backgroundSize: '60%',
                            },
                            destinationName: 'The Chedi Andermatt, Andermatt, Switzerland',
                            htId: 'Accommodation_27152968',
                        },
                    ]}
                />
                <Tiles
                    list={[
                        {
                            city: 'The Dolder Grand Switzerland',
                            image: '/images/hotels/partnership/dolder.jpg',
                            destinationName: 'The Dolder Grand, Zurich, Zurich Canton, Switzerland',
                            htId: 'Accommodation_27073322',
                        },
                        {
                            city: 'Kimpton Maa-lai Bangkok',
                            image: '/images/hotels/partnership/kimpton.jpg',
                            destinationName: 'Kimpton Maa Lai Bangkok, An Ihg Hotel, Bangkok, Thailand',
                            htId: 'Accommodation_27033324',
                        },
                        {
                            city: 'Lotte City Hotel Tashkent Palace',
                            image: '/images/hotels/partnership/lotte-city.jpg',
                            destinationName: 'Lotte City Hotel Tashkent Palace, Toshkent, Uzbekistan',
                            htId: 'Accommodation_27091778',
                        },
                    ]}
                />
            </section>
        </div>
    </>
));

export default AccommodationTitlePage;
