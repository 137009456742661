import React, { useEffect } from 'react';
import { date } from 'simple';
import { $accommodation, $auth } from 'stores';
import AccommodationSearchForm from '../search-form/search-form';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchNothingFound = ({ resultCount, filters, clearFilters, condition }) => {
    const location = useLocation();
    const isSearch = location.pathname === '/search/results';
    const isSearchEmpty = location.pathname === '/search/results/empty';
    const navigate = useNavigate();

    useEffect(() => {
        if (isSearch && condition && !filters) {
            navigate('/search/results/empty');
        }
        if (isSearchEmpty && !condition) {
            navigate('/search/results');
        }
    }, [isSearch, navigate, isSearchEmpty, condition, filters]);

    if (!condition) {
        return null;
    }

    const WhatToDo = (
        <div className="head">
            <div className="title">
                <h3>Can't find what you're looking for?</h3>
                {!$auth.agencySettings.acceptLastMinuteBookings &&
                    $accommodation.search.request.checkInDate === date.format.apiDateOnly(new Date()) &&
                    !filters && (
                        <div>
                            Booking under the deadline, please contact your account manager and process the
                            search for arrival date starting tomorrow.
                        </div>
                    )}
                <div>
                    You can contact our Operations team directly, and they can help you with your
                    accommodations.
                </div>
                <div>
                    Email:{' '}
                    <a href="mailto:reservations@happytravel.com" className="link">
                        reservations@happytravel.com
                    </a>
                </div>
            </div>
        </div>
    );

    if (!resultCount || !filters) {
        return (
            <div className="nothing-found empty-page">
                <div className="text">
                    <h2>No accommodations available</h2>
                    {WhatToDo}
                </div>
                <AccommodationSearchForm fullsize />
            </div>
        );
    }

    return (
        <div className="nothing-found">
            <div className="text">
                <h2>There are no results that match selected filters</h2>
                <div className="button" onClick={clearFilters}>
                    Clear Filters
                </div>
                {WhatToDo}
            </div>
        </div>
    );
};

export default SearchNothingFound;
